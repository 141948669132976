import { createAction, props, union } from '@ngrx/store';
import {
  Branding,
  GetBrandingCssTemplatePayload,
} from '../../models/branding.model';

export const GetBrandingCssTemplateAction = createAction(
  '[BtPay] Branding Get Css Template',
  props<{
    payload: GetBrandingCssTemplatePayload;
  }>(),
);

export const GetBrandingCssTemplateCompleteAction = createAction(
  '[BtPay] Branding Get Css Template Complete',
  props<{
    payload: string; // css style is rendered as plain text,
  }>(),
);

export const GetBrandingDataForTenantAction = createAction(
  '[BtPay] Branding Get Branding Data for Tenant',
  props<{
    payload: { tenantId: string }; // tenant id
  }>(),
);

export const GetBrandingDataForTenantCompleteAction = createAction(
  '[BtPay] Branding Get Branding Data for Tenant Complete',
  props<{
    payload: Branding;
  }>(),
);

const BrandingActions = union({
  GetBrandingCssTemplateAction,
  GetBrandingCssTemplateCompleteAction,
  GetBrandingDataForTenantAction,
  GetBrandingDataForTenantCompleteAction,
});

export type BrandingActionsUnion = typeof BrandingActions;
