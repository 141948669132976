/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppConfigSettings } from '@arc/arc-shared-frontend';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

// const httpClientInjector1 = Injector.create([
//   { provide: HttpClient, deps: [HttpHandler] },
//   { provide: HttpHandler, useClass: HttpXhrBackend, deps: [XhrFactory] },
//   { provide: XhrFactory, useClass: ɵangular_packages_common_http_http_d, deps: [] }
// ]);

if (environment.production) {
  enableProdMode();
}

// const http = httpClientInjector1.get(HttpClient);

fetch('/assets/app.settings.json')
  .then((response) => response.json())
  .then((config) => {
    const configSetting = new AppConfigSettings();
    if (config && config.length > 0) {
      config.forEach((element) => {
        for (const key in element) {
          // eslint-disable-next-line no-prototype-builtins
          if (element.hasOwnProperty(key)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            configSetting.settings.set(key, element[key]);
          }
        }
      });
    }
    if (configSetting.getconfig('appMode') === 'prod') {
      enableProdMode();
    }
    platformBrowserDynamic([
      { provide: AppConfigSettings, useValue: configSetting },
    ]).bootstrapModule(AppModule);
  });
