import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  BtPayAnalyticsActions,
  SessionActions,
} from '@arc/unified-payments-frontend/shared-stores';
import { filter, map, exhaustMap } from 'rxjs/operators';
import { PendoService } from '@arc/arc-shared-frontend';
import { EMPTY } from 'rxjs';

@Injectable()
export class PendoEffects {
  constructor(
    private actions$: Actions<BtPayAnalyticsActions.BtPayAnalyticsActionsUnion>,
    private pendoService: PendoService,
  ) {}

  sendAnalyticsToPendo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BtPayAnalyticsActions.TrackAnalyticsAction.type),
        map((action) => {
          this.pendoService.trackEvent(
            action.eventKey,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            action.eventData,
          );
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  sendBuyerCompanyNameToPendo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.GetSessionSummarySuccessAction),
        map((action) => action.payload),
        filter((payload) => payload.buyerCompanyName != null),
        exhaustMap((payload) => {
          this.pendoService.includeAdditionalContext(null, {
            buyerName: payload.buyerCompanyName,
          });
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );
}
