<div id="payment-header-container" class="topbar">
  <img
    *ngIf="logoImageUrl"
    id="branding-logo"
    alt="Billtrust"
    src="{{ logoImageUrl }}"
  />

  <payment-language-dropdown-container id="payment-language-dropdown"></payment-language-dropdown-container>
</div>
