import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentRulesResponseV1 } from '@arc/unified-payments-frontend/shared-payment';
import { PaymentRuleStore } from '@arc/unified-payments-frontend/shared-stores';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.container.component.html',
  styleUrls: ['./checkout.container.component.scss'],
})
export class CheckoutContainerComponent implements OnInit, OnDestroy {
  paymentRule$: Observable<PaymentRulesResponseV1>;

  private _subSink = new SubSink();

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }

  ngOnInit(): void {
    this.paymentRule$ = this.store.pipe(select(PaymentRuleStore));
    return null;
  }
}
