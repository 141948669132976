<div arcSharedUserActive class="layout-wrapper" (click)="onLayoutClick()">
  <aur-loading-spinner
    class="spinner"
    *ngIf="showBlockUILoading"
  ></aur-loading-spinner>
  <div
    #layoutContainer
    class="layout-container menu-layout-static menu-layout-horizontal"
  >
    <app-payment-header
      *ngIf="isloggedin$ | async"
      [logoImageUrl]="headerLogoUrl"
    ></app-payment-header>
    <div [ngClass]="{ 'layout-main': isloggedin$ | async }">
      <div class="layout-content" *ngIf="!loading">
        <router-outlet id="arcMainContent"></router-outlet>
      </div>
    </div>
  </div>
  <app-sessiontimeout *ngIf="showSessionTimeOut$ | async"></app-sessiontimeout>
</div>
