import { Injectable, OnDestroy } from '@angular/core';
import {
  ActiveTenantStore,
  AppConfigSettings,
  UserTenant,
} from '@arc/arc-shared-frontend';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { DataDogService } from './data-dog.service';

@Injectable({
  providedIn: 'root',
})
export class AppMonitoringService implements OnDestroy {
  private enableLogging: boolean;
  private enableRum: boolean;
  private subSink = new SubSink();

  constructor(
    private appConfigSetting: AppConfigSettings,
    private dataDogService: DataDogService,
    private store: Store<UserTenant>,
  ) {}

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  init(): Observable<void> {
    this.enableLogging =
      this.appConfigSetting.getconfig('enableBrowserLogging') === 'true';
    this.enableRum =
      this.appConfigSetting.getconfig('enableBrowserRum') === 'true';

    this.initializeUserStore();
    return of();
  }

  private initializeUserStore(): void {
    if (!this.enableRum) {
      return;
    }

    const subscription = this.store
      .pipe(
        select(ActiveTenantStore),
        filter((tenant) => tenant != null),
        take(1),
      )
      .subscribe((tenant) => {
        const tenantType = tenant.tenantType.toLowerCase();

        if (tenantType === 'automation') {
          this.enableRum = false;
          this.enableLogging = false;
          return;
        }

        this.dataDogService.initializeRum();
      });

    this.subSink.add(subscription);
  }

  logError(error: unknown, context?: Record<string, unknown>): void {
    if (!this.enableLogging) {
      return;
    }

    this.dataDogService.logError(error, context);
  }
}
