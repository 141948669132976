import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GeneralErrorAction } from '@arc/arc-shared-frontend';
import { of } from 'rxjs';
import { BrandingActions } from '../actions/index';
import {
  BrandingActionsUnion,
  GetBrandingCssTemplateCompleteAction,
  GetBrandingDataForTenantCompleteAction,
} from '../actions/branding.actions';
import { BrandingService } from '../../services/branding.service';
import { GetBrandingCssTemplatePayload } from '../../models/branding.model';

@Injectable()
export class BrandingEffects {
  constructor(
    private actions$: Actions<BrandingActionsUnion>,
    private brandingService: BrandingService,
  ) {}

  getBrandingCssTemplateAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandingActions.GetBrandingCssTemplateAction.type),
      map((action) => action.payload),
      mergeMap((payload: GetBrandingCssTemplatePayload) =>
        this.brandingService
          .getCSSTemplate(payload.tenantId, payload.appName)
          .pipe(
            map((response) =>
              GetBrandingCssTemplateCompleteAction({ payload: response.body }),
            ),
            catchError(() =>
              of(
                new GeneralErrorAction({
                  actionType: BrandingActions.GetBrandingCssTemplateAction.type,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getBrandingForTenantAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandingActions.GetBrandingDataForTenantAction.type),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.brandingService
          .v1TenantsTenantIdBrandingGet(payload.tenantId)
          .pipe(
            map((tenantBrandingData) =>
              GetBrandingDataForTenantCompleteAction({
                payload: tenantBrandingData,
              }),
            ),
            catchError(() =>
              of(
                new GeneralErrorAction({
                  actionType:
                    BrandingActions.GetBrandingDataForTenantAction.type,
                }),
              ),
            ),
          ),
      ),
    ),
  );
}
