/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { AppConfigSettings } from '@arc/arc-shared-frontend';
import { Observable } from 'rxjs';
import { Branding } from '../models/branding.model';

@Injectable({ providedIn: 'root' })
export class BrandingService {
  constructor(
    private appConfig: AppConfigSettings,
    private httpClient: HttpClient,
  ) {}

  getCSSTemplate(
    tenantId: string,
    cssTemplate = 'btpay',
  ): Observable<HttpResponse<string>> {
    const url = new URL(
      `${this.appConfig.getconfig(
        'brandingServiceBaseUrl',
      )}/v1/tenants/${tenantId}/css/template/${cssTemplate}`,
    );
    const httpHeaders = new HttpHeaders().set('Content-Type', 'text/css');
    return this.httpClient.get(url.href.toString(), {
      headers: httpHeaders,
      observe: 'response',
      responseType: 'text',
    });
  }

  getTenantBrandingInfo(tenantId: string): Observable<HttpResponse<string>> {
    const url = new URL(
      `${this.appConfig.getconfig(
        'brandingServiceBaseUrl',
      )}v1/tenants/${tenantId}`,
    );
    const httpHeaders = new HttpHeaders().set('Content-Type', 'text/css');
    return this.httpClient.get(url.href.toString(), {
      headers: httpHeaders,
      observe: 'response',
      responseType: 'text',
    });
  }

  /**
   * Get branding data for a tenant
   *
   * @param tenantId TenantId of the Branding you are retrieving.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public v1TenantsTenantIdBrandingGet(
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Branding>;
  public v1TenantsTenantIdBrandingGet(
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Branding>>;
  public v1TenantsTenantIdBrandingGet(
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Branding>>;
  public v1TenantsTenantIdBrandingGet(
    tenantId: string,
    observe: any = 'body',
    reportProgress = false,
  ): Observable<any> {
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling v1TenantsTenantIdBrandingGet.',
      );
    }

    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return this.httpClient.request<Branding>(
      'get',
      `${this.appConfig.getconfig(
        'brandingServiceBaseUrl',
      )}/v1/tenants/${encodeURIComponent(String(tenantId))}`,
      {
        headers,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        observe,
        reportProgress,
      },
    );
  }

  updateBrandingColorsByLinkTag(tenantId: string, cssTemplate = 'btpay'): void {
    const eleId = 'brandingCssLink';
    const ele = document.getElementById(eleId);
    if (ele) {
      ele.remove();
    }
    if (tenantId) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const styleLink = document.createElement('link');
      styleLink.setAttribute('rel', 'stylesheet');
      styleLink.setAttribute('id', eleId);
      styleLink.setAttribute(
        'href',
        `${this.appConfig.getconfig(
          'brandingServiceBaseUrl',
        )}/v1/tenants/${tenantId}/css/template/${cssTemplate}`,
      );
      head.appendChild(styleLink);
    }
  }

  /* eslint-disable class-methods-use-this */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */

  updateBrandingColorsByCssStyle(styleString): void {
    const eleId = 'brandingCss';
    const ele = document.getElementById(eleId);
    if (ele) {
      ele.remove();
    }
    const head = document.head || document.getElementsByTagName('head')[0];
    const styleTag = document.createElement('style');
    styleTag.setAttribute('id', 'brandingCss');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    styleTag.appendChild(document.createTextNode(JSON.parse(styleString)));
    head.appendChild(styleTag);
  }

  updateBrandingColorsByStyleTag(cssData: string): void {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    head.appendChild(style);
    style.appendChild(document.createTextNode(cssData));
  }
}
