import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AuthActionTypes,
  BrowserCookieService,
  CoreService,
} from '@arc/arc-shared-frontend';
// eslint-disable-next-line import/no-unresolved
import { Tenant } from '@arc/arc-shared-frontend/lib/services/serviceclient/model/tenant';
import { Actions, ofType } from '@ngrx/effects';
import { SubSink } from 'subsink';

export interface BuyerLoginConfig {
  applicationId: string;
  featureId: string;
  personaId: string;
}

@Component({
  selector: 'app-buyerlogin-container',
  templateUrl: './buyerlogin.container.component.html',
  styleUrls: ['./buyerlogin.container.component.css'],
})
export class BuyerLoginContainerComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private cookieService: BrowserCookieService,
    private actions$: Actions,
    private coreService: CoreService,
  ) {}

  tenantId: string;
  config: BuyerLoginConfig;

  private _subSink = new SubSink();

  ngOnInit(): void {
    this.config = this.activatedRoute.snapshot.data.config as BuyerLoginConfig;

    this.cookieService.setSessionCookiePerEnvEncoded(
      '__bt_pf_appId',
      this.config.applicationId,
    );
    this.cookieService.setSessionCookiePerEnvEncoded(
      '__bt_pf_featureId',
      this.config.featureId,
    );
    this.cookieService.setSessionCookiePerEnvEncoded(
      '__bt_pf_personaId',
      this.config.personaId,
    );

    this._subSink.add(
      this.actions$.pipe(ofType(AuthActionTypes.LoginByToken)).subscribe(() => {
        this.coreService.isLoading = true;
      }),
    );
    this._subSink.add(
      this.actions$
        .pipe(
          ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.LoginFailure),
        )
        .subscribe(() => {
          this.coreService.isLoading = false;
        }),
    );

    this._subSink.add(
      this.activatedRoute.queryParams.subscribe((x: Tenant) => {
        if (x && x.tenantId) {
          this.tenantId = x.tenantId;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }
}
