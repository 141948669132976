import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { DatadogEffects } from './datadog.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([DatadogEffects])],
})
export class DatadogStoreModule {}
