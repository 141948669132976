import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Branding } from '../../models/branding.model';
import { BrandingActions } from '../actions';

export interface BrandingStoreState {
  brandingData: Branding;
}

export const initialState: BrandingStoreState = {
  // State
  brandingData: null,
};

export const btPayBrandingReducers = createReducer(
  initialState,
  on(
    BrandingActions.GetBrandingDataForTenantCompleteAction,
    (state, action) => ({
      ...state,
      brandingData: action.payload,
    }),
  ),
);

export const PayStore =
  createFeatureSelector<BrandingStoreState>('btpay_branding');

export const tenantBrandingDataMap = (state: BrandingStoreState): Branding =>
  state.brandingData;

export const TenantBrandingDataStore = createSelector(
  PayStore,
  tenantBrandingDataMap,
);
