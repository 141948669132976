import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthActionTypes, CoreService } from '@arc/arc-shared-frontend';
import { Actions, ofType } from '@ngrx/effects';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-supplierlogin-container',
  templateUrl: './supplierlogin.container.component.html',
  styleUrls: ['./supplierlogin.container.component.scss'],
})
export class SupplierLoginContainerComponent implements OnInit, OnDestroy {
  constructor(
    private actions$: Actions,
    private coreService: CoreService,
  ) {}

  tenantId;
  private _subSink = new SubSink();

  ngOnInit(): void {
    this._subSink.add(
      this.actions$.pipe(ofType(AuthActionTypes.LoginByToken)).subscribe(() => {
        this.coreService.isLoading = true;
      }),
    );
    this._subSink.add(
      this.actions$
        .pipe(
          ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.LoginFailure),
        )
        .subscribe(() => {
          this.coreService.isLoading = false;
        }),
    );
  }

  ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }
}
