/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';

import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import {
  CoreService,
  DisplaySessionTimeoutWarningAction,
  GetSessionTTLAction,
} from '@arc/arc-shared-frontend';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { SessionStore } from '@arc/unified-payments-frontend/shared-stores';
import { Router } from '@angular/router';

@Injectable()
export class CheckoutSessionTTLInterceptor implements HttpInterceptor {
  private readonly MAX_REMINDER_MILLISECONDS = 90 * 1000;

  private timeoutUrl: string;

  constructor(
    private store: Store<any>,
    private coreService: CoreService,
    private ngZone: NgZone,
    private router: Router,
  ) {
    this.subscribeToSessionSummaryUpdates();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.url.toLowerCase().endsWith('/session/ttl') &&
      request.method === 'GET'
    ) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          this.redirectUserToTimeoutUrl();
          return throwError(error);
        }),
        tap((event: HttpEvent<any>) => {
          if (!(event instanceof HttpResponse)) {
            return;
          }

          if (event.status > 199 && event.status < 301) {
            const ttlInMilliseconds =
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              parseInt(event.body.ttlInSeconds, 10) * 1000;
            const timeForReminderMilliseconds = Math.max(
              ttlInMilliseconds - this.MAX_REMINDER_MILLISECONDS,
              0,
            );

            this.ngZone.runOutsideAngular(() => {
              if (timeForReminderMilliseconds > 10000 /* 10 Seconds */) {
                this.store.dispatch(
                  new DisplaySessionTimeoutWarningAction({
                    display: false,
                    countDown: 0,
                  }),
                );

                this.coreService.setSessionTimeoutTimer(
                  timeForReminderMilliseconds - 5000 /* 5 Seconds */,
                  () => {
                    this.ngZone.run(() => {
                      this.store.dispatch(new GetSessionTTLAction());
                    });
                  },
                );
              } else {
                this.ngZone.run(() => {
                  this.store.dispatch(
                    new DisplaySessionTimeoutWarningAction({
                      display: true,
                      countDown: this.MAX_REMINDER_MILLISECONDS,
                    }),
                  );
                });
              }
            });
          } else {
            this.redirectUserToTimeoutUrl();
          }
        }),
      );
    }
    return next.handle(request);
  }

  private subscribeToSessionSummaryUpdates(): void {
    this.store
      .pipe(
        select(SessionStore),
        filter((data) => !!data && !!data.sessionId),
        take(1),
      )
      .subscribe((sessionSummary) => {
        this.timeoutUrl =
          sessionSummary?.onSessionTimeoutUrl ||
          sessionSummary?.onSessionCompleteUrl;
      });
  }

  private redirectUserToTimeoutUrl(): void {
    this.coreService.cancelSessionTimeoutTimer();

    if (!this.timeoutUrl) {
      this.router.navigate(['/sessiontimeout']);
      return;
    }

    this.ngZone.run(() => {
      window.location.href = this.timeoutUrl;
    });
  }
}
