import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CommonModule } from '@angular/common';
import { BrandingEffects } from './effects/branding.effects';
import { btPayBrandingReducers } from './reducers/branding.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('btpay_branding', btPayBrandingReducers),
    EffectsModule.forFeature([BrandingEffects]),
  ],
  exports: [],
})
export class BrandingStoreModule {}
