import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-header',
  templateUrl: './payment-header.component.html',
  styleUrls: ['./payment-header.component.scss'],
})
export class PaymentHeaderComponent {
  @Input() logoImageUrl = './assets/layout/images/logo.svg';
}
