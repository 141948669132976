/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  ArcSharedModule,
  clearStateMetaReducer,
  ApiRequestRetryInterceptor,
  SessionTimeOutInterceptor,
  TokenInterceptor,
  PendoService,
} from '@arc/arc-shared-frontend';
import { PaymentFooterModule } from '@arc/unified-payments-frontend/payment-footer';
import { I18nModule } from '@arc/unified-payments-frontend/i18n';
import { StoreModule, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

// Locales for supported languages
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeNo from '@angular/common/locales/no';
import localeSv from '@angular/common/locales/sv';

/* Import prism core */
import 'prismjs/prism';
/* Import the language you need to highlight */
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-typescript';
import { DragulaModule } from 'ng2-dragula';
import { ArcUserModule } from '@arc/arc-user-frontend';

import { AureusModule, CardModule } from '@arc/aureus';
import { DropdownModule } from 'primeng/dropdown';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { environment } from '../environments/environment';
import { Error401Component } from './errorpages/error401.component';
import { CheckoutContainerComponent } from './checkout/checkout.container.component';
import { PaymentHeaderComponent } from './payment-header/payment-header.component';
import { BrandingStoreModule } from './store/branding/branding.store.module';
import { BuyerLoginContainerComponent } from './checkout/buyerlogin/buyerlogin.container.component';
import { SupplierLoginContainerComponent } from './checkout/supplierlogin/supplierlogin.container.component';
import { SessionTimeoutContainerComponent } from './session/sessiontimeout.component';
import { CheckoutSessionTTLInterceptor } from './intercepter/sessionttl.intercepter';
import { PendoStoreModule } from './store/pendo/pendo.store.module';
import {
  AppHomeComponent,
  AppMenuComponent,
  AppSubMenuComponent,
  AppFooterComponent,
  AppInlineProfileComponent,
  AppRightpanelComponent,
  AppTopbarComponent,
} from './shared';
import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { BreadcrumbService } from './breadcrumb.service';
import { AppMonitoringService } from './services/app-monitoring.service';
import { DatadogStoreModule } from './store/datadog/datadog.store.module';

// Add the locale data for all the supported languages
registerLocaleData(localeDa);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFi);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localeNo);
registerLocaleData(localeSv);

export const reducers: ActionReducerMap<any> = { router: routerReducer };

export const metaReducers: MetaReducer<any>[] = [clearStateMetaReducer];

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private appMonitoringService: AppMonitoringService) {}

  handleError(err: unknown): void {
    console.log(err);
    this.appMonitoringService.logError(err);
  }
}
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AureusModule,
    DropdownModule,
    CardModule,
    AppRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    BrandingStoreModule,
    PendoStoreModule,
    DatadogStoreModule,
    PaymentFooterModule,
    I18nModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
      },
    }),
    EffectsModule.forRoot([]),
    ArcSharedModule,
    ArcUserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
    }),
    DragulaModule.forRoot(),
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    AppHomeComponent,
    AppMenuComponent,
    AppFooterComponent,
    AppSubMenuComponent,
    AppInlineProfileComponent,
    AppTopbarComponent,
    AppRightpanelComponent,
    AppBreadcrumbComponent,
    Error401Component,
    CheckoutContainerComponent,
    PaymentHeaderComponent,
    BuyerLoginContainerComponent,
    SupplierLoginContainerComponent,
    SessionTimeoutContainerComponent,
  ],
  providers: [
    HttpClientModule,
    PendoService,
    {
      provide: 'appName',
      useValue: environment.appName,
    },
    {
      provide: APP_INITIALIZER,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => () =>
        translateService.use('en').toPromise(),
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AppMonitoringService],
      useFactory: (monitoringService: AppMonitoringService) => () =>
        monitoringService.init(),
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestRetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionTimeOutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckoutSessionTTLInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    BreadcrumbService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
