import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  filter,
  takeWhile,
  switchMap,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { Observable, interval } from 'rxjs';
import { SubSink } from 'subsink';
import {
  AuthUserStore,
  DisplaySessionTimeoutWarningAction,
  GetSessionTTLAction,
  UserAliveAction,
  UserState,
} from '@arc/arc-shared-frontend';
import { SessionStore } from '@arc/unified-payments-frontend/shared-stores';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sessiontimeout',
  templateUrl: './sessiontimeout.component.html',
  styleUrls: ['./sessiontimeout.component.scss'],
})
export class SessionTimeoutContainerComponent implements OnInit, OnDestroy {
  blockUI = true;

  private _subsink = new SubSink();
  private _resumeTimer = true;
  private _sessionTimeoutUrl = '';
  private _countDown = 0;

  constructor(
    private store: Store<UserState>,
    private router: Router,
  ) {}

  get countDownInSeconds(): number {
    return Math.max(0, parseInt(`${this._countDown / 1000}`, 10));
  }

  ngOnInit(): Observable<number> | void {
    this._subsink.add(
      this.store
        .pipe(
          select(SessionStore),
          filter((data) => !!data && !!data.sessionId),
        )
        .subscribe((sessionSummary) => {
          this._sessionTimeoutUrl =
            sessionSummary?.onSessionTimeoutUrl ||
            sessionSummary?.onSessionCompleteUrl;
        }),
    );

    this._subsink.add(
      this.store
        .pipe(
          select(AuthUserStore),
          filter((x) => x.sessionTimeOutPopUp),
          map((x) => ({
            sessionTimeOutPopUp: x.sessionTimeOutPopUp,
            sessionTimeOutTime: x.sessionTimeOutTime,
          })),
          distinctUntilChanged(
            (prev, curr) =>
              prev.sessionTimeOutPopUp === curr.sessionTimeOutPopUp &&
              prev.sessionTimeOutTime === curr.sessionTimeOutTime,
          ),
          switchMap(
            (x: {
              sessionTimeOutPopUp: boolean;
              sessionTimeOutTime: number;
            }) => {
              this._countDown = x.sessionTimeOutTime;

              return interval(1000).pipe(takeWhile(() => this._resumeTimer));
            },
          ),
        )
        .subscribe(() => {
          this._countDown -= 1000;
          this.store.dispatch(new GetSessionTTLAction());

          if (this._countDown <= 0) {
            this.navigateToTimeoutUrl();
          }
        }),
    );
    return null;
  }

  ngOnDestroy(): void {
    this._subsink.unsubscribe();
  }

  onExtendSessionClick(): void {
    this.blockUI = false;
    this._resumeTimer = false;

    this.store.dispatch(
      new DisplaySessionTimeoutWarningAction({ display: false, countDown: 0 }),
    );

    this.store.dispatch(new UserAliveAction({}));
  }

  onEndSessionClick(): void {
    this.navigateToTimeoutUrl();
  }

  private navigateToTimeoutUrl(): void {
    if (!this._sessionTimeoutUrl) {
      this.router.navigate(['/sessiontimeout']);
      return;
    }

    window.location.href = this._sessionTimeoutUrl;
  }
}
