<p-dialog
  id="timeout-dialog"
  [modal]="true"
  [closeOnEscape]="false"
  width="400"
  [visible]="true"
  [resizable]="false"
  (visible)="onExtendSessionClick()"
  [responsive]="true"
  [header]="'BTPAY_SESSION_TIMEOUT_WARNING_HEADER' | translate"
  [closable]="false"
>
  <p>
    {{
      'ARC_SHARED_SESSION_TIMEOUT_WARNING_MESSAGE_TIME_LEFT'
        | translate: { count: countDownInSeconds }
    }}
  </p>
  <footer>
    <button
      id="timeout-dialog-return-origin-button"
      type="button"
      importance="secondary"
      aur-button
      (click)="onEndSessionClick()"
      translate
    >
      {{ 'ARC_PAYMENT_RETURN' | translate }}
    </button>
    <button
      id="timeout-dialog-extend-session-button"
      type="button"
      aur-button
      (click)="onExtendSessionClick()"
    >
      {{
        'ARC_SHARED_SESSION_TIMEOUT_WARNING_STAY_LOGGED_IN_BUTTON_TEXT'
          | translate
      }}
    </button>
  </footer>
</p-dialog>
